function initGdpr(gdprCookieNotice) {
  gdprCookieNotice({
    locale: "en", //This is the default value
    timeout: 500, //Time until the cookie bar appears
    expiration: 30, //This is the default value, in days
    // domain: ".127.0.0.1",
    domain: ".endatix.com", //If you run the same cookie notice on all subdomains, define the main domain starting with a .
    implicit: true, //Accept cookies on scroll
    statement: "https://google.com", //Link to your cookie statement page
    performance: ["JSESSIONID"], //Cookies in the performance category.
    analytics: ["ga"], //Cookies in the analytics category.
    marketing: ["SSID"], //Cookies in the marketing category.
  });
}

document.addEventListener("DOMContentLoaded", () => {
  const gdprCookieName = "gdprcookienotice";
  const cookiesEnabledEventName = "gdprCookiesEnabled";
  const gdprCookie = getCookie(gdprCookieName);
  if (gdprCookie) {
    const trackingPreferences = getTrackingPreferences(gdprCookie);
    const cookiesAcceptedEvent = new CustomEvent(cookiesEnabledEventName, {
      detail: trackingPreferences,
    });
    document.dispatchEvent(cookiesAcceptedEvent);
  } else {
    import("@/js/gdpr-dialog").then(({ gdprCookieNotice }) => {
      initGdpr(gdprCookieNotice);
    });
  }
});

const getCookie = (cookieName) => {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const getTrackingPreferences = (gdprCookie) => {
  let trackingPreferences = {};
  const minimumViableLength = 52;
  if (!gdprCookie || gdprCookie.length < minimumViableLength) {
    return trackingPreferences;
  }

  try {
    var data = JSON.parse(gdprCookie);
    const categories = ["necessary", "performance", "analytics", "marketing"];
    categories.forEach((category) => {
      trackingPreferences[category] = data[category] ?? false;
    });
    return trackingPreferences;
  } catch {
    return trackingPreferences;
  }
};
